import React from "react";

import images from "../../constants/images";
import "./Services.css";

const awards = [
	{
		imgUrl: images.nagarCastle,
		title: "Naggar Castle & Roerich Art Gallery",
		subtitle:
			"A unique experience of the history of Naggar Castle & Roerich Art Gallery",
	},
	{
		imgUrl: images.waterfall,
		title: "Jana Waterfall",
		subtitle: "30-foot high waterfall near Manali in quaint Jana village",
	},
	{
		imgUrl: images.track,
		title: "Trek to Soeil Village",
		subtitle: "Hidden Serenity : Soyal Village Trek in Manali",
	},
	{
		imgUrl: images.paragliding,
		title: "Highest paragliding in Kullu Manali",
		subtitle: "Thrilling Paragliding at 9000 feet",
	},
	{
		imgUrl: images.rafting,
		title: "White water rafting on the Beas River",
		subtitle: "Thrilling Himalayan Rapids Adventure",
	},
	{
		imgUrl: images.cycling,
		title: "Cycling Downhill and Fishing",
		subtitle: "Thrilling Downhill Cycling Adventure in Manali",
	},
];

const Services = () => (
	<section>
		<div className="pt-20 scroll-pb-40">
			<h1 className="headtext">Nearby Attractions</h1>
		</div>
		<div className="text-gray-600 body-font">
			<div className="container px-5 py-14 pb-10 mx-auto">
				<div className="flex flex-wrap -m-4">
					{awards.map((award, index) => (
						<div className="p-4 md:w-1/3" key={index}>
							<div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
								<img
									className="lg:h-48 md:h-36 w-full object-cover object-center"
									src={award.imgUrl}
									alt="blog"
								/>
								<div className="p-6">
									<h1 className="title-font text-xl font-medium text-gray-900 mb-3 p__cormorant">
										<b>{award.title}</b>
									</h1>
									<h6 className="mb-3 text-base font-light ">
										<b>{award.subtitle}</b>
									</h6>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	</section>
);

export default Services;
