import { Card, Text } from "@nextui-org/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Card.css";

const Card4 = ({ image, title, duration, information, price }) => (
  <Card css={{ w: "100%", h: "400px" }} className="cardhover">
    <Card.Body css={{ p: 0 }}>
      <Card.Image
        src={image}
        width="100%"
        height="100%"
        objectFit="cover"
        alt="Card example background"
      />
    </Card.Body>
    <Card.Footer
      isBlurred
      css={{
        position: "absolute",
        bgBlur: "#ffffff66",
        borderTop: "$borderWeights$light solid rgba(255, 255, 255, 0.2)",
        bottom: 0,
        zIndex: 1,
        cursor: "pointer",
      }}
    >
      {/* <Row style={{alignItems:"center",display:"flex"}}>
                <Col>
                    <Text color="#000" size={19}>
                        {title} {duration}
                    </Text>
                </Col>
                <Col>
                    <Row justify="flex-end ">
                            <Text
                                css={{ color: "#fff" }}
                                size={14}
                                weight="bold"
                                transform="uppercase">
                                Know More
                            </Text>
                    </Row>
                </Col>
            </Row> */}
      <Container>
        <Row>
          <Col>
            <h5 color="#000" ><b>{title} {information}</b></h5>
          </Col>
        </Row>
        <Row>
            <Col>
                <h6><b>{duration}</b></h6>
            </Col>
        </Row>
        <Row>
          <Col>
            <Text
              css={{ color: "#fff" }}
              size={14}
              weight="bold"
              transform="uppercase"
            >
              Know More
            </Text>
          </Col>
          <Col>
            <h5 style={{color : "white"}}>
                <b>{price}</b>
            </h5>
          </Col>
        </Row>
      </Container>
    </Card.Footer>
  </Card>
);

export default Card4;
