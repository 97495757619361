import React, { useState, useEffect } from "react";
import images from "../../constants/images";
import "./Header.css";
import { Carousel } from "react-bootstrap";

const Header = () => {
  const [isPhoneScreen, setIsPhoneScreen] = useState(window.innerWidth <= 480);
  const [showFirstPoster, setShowFirstPoster] = useState(true);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const getNextIcon = () => {
    return(
      <img src ={images.next} style ={{ width: "3.5rem", height: "3.5rem"}} className='nextbutton'></img>
    )
  }

  const getPrevIcon = () => {
    return(
      <img src = {images.previous} style = {{ width: "3.5rem", height: "3.5rem" }} className='prevbutton'></img>
    )
  }

  useEffect(() => {
    const handleResize = () => {
      setIsPhoneScreen(window.innerWidth <= 480);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setShowFirstPoster((prevShowFirstPoster) => !prevShowFirstPoster);
    }, 2000); // Change the interval duration as per your requirement

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="app__header app__wrapper" id="home" style={{height: ""}}>
      {/* <div className="app__wrapper_info">
        <p className="p__opensans head health" style={{ color: 'white', fontWeight: 'bolder' }}>
          Health and Wellness Resort
        </p>
        <h1 className="app__header-h1 head" style={{ color: 'white' }}>
          Welcome to Panchtatva
        </h1>
      </div> */}

      {/* <div className="video">
        {isPhoneScreen ? (
          <video
            preload="auto"
            className="videostyles"
            autoPlay
            loop
            muted
            poster={showFirstPoster ? images.pain : images.yoga}
          >
            <source src={images.mobileVideo} type="video/webm" />
          </video>
        ) : (
          <video
            preload="auto"
            className="videostyles"
            autoPlay
            loop
            muted
            poster={showFirstPoster ? images.pain : images.yoga}
          >
            <source src={images.desktopVideo} type="video/webm" />
          </video>
        )}
      </div> */}

      <Carousel nextIcon = {getNextIcon()} prevIcon = {getPrevIcon()} indicators = {false} className = "Carousel">
        <Carousel.Item>
          <div>
            <div style={{backgroundColor : '#040128'}}>
              <img src={images.home1} className="d-block carouselimg object-cover" alt="..."/>
            </div>
            <h1 className="carousel-text app__header-h1 head">Welcome to the Panchtatva Resorts, your home in the Himalayas</h1>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div style={{backgroundColor : '#040128'}}>
            <img src={images.home2} className="d-block carouselimg object-cover" alt="..." />
            <h1 className="carousel-text app__header-h1 head">Discover the art of relaxation amidst mountain grandeur</h1>
          </div>
        </Carousel.Item>
        <Carousel.Item>
        <div style={{backgroundColor : '#040128'}}>
          <img src={images.home3} className="d-block carouselimg object-cover" alt="..." />
          <h1 className="carousel-text app__header-h1 head">Our commitment to excellence extends beyond our accommodations</h1>
        </div>
        </Carousel.Item>
      </Carousel>
    </div> 
  );
};

export default Header;
