import React from "react";
import Card from "../../components/Rcard/RCard";
import { Grid } from "@nextui-org/react";
import './Room.css';
import images from '../../constants/images';
import data from "../../constants/data";

export default function App() {
    return (
        <>
            <div className="container-with-background" id="room">
                <div>
                <h6 style={{color: 'white'}}></h6>
                    <h1 className="headtext">Rooms</h1>
                </div>
                <Grid.Container gap={2} justify="center">
                    <Grid xs={12} sm={4}>
                        <Card image={images.prithvi} title="Prithvi kaksh" description={data.Prithvi} price="₹4,500 Per Night, Twin Sharing"/>
                    </Grid>
                    <Grid xs={12} sm={4}>
                        <Card image={images.vayu} title="Vayu kaksh" description={data.Vayu} price="₹5,500 Per Night, Twin Sharing"/>
                    </Grid>
                    <Grid xs={12} sm={4}>
                        <Card image={images.akash} title="Akash kaksh" description={data.Akash} price="₹6,500 Per Night, Twin Sharing"/>
                    </Grid>
                </Grid.Container>
            </div>
        </>
    );
}



