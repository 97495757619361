import React from "react";
import { useRef } from 'react';
import { Card, Text } from "@nextui-org/react";
import './RCard.css'
import { Modal, Input, Grid , Button } from "@nextui-org/react";
import emailjs, { send } from '@emailjs/browser';

const Card2 = ({image, title, description, price}) => {
    const [visible, setVisible] = React.useState(false);
    const handler = () => setVisible(true);
    const closeHandler = () => {
        setVisible(false);
        console.log("closed");
    };

    const currentDate = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format

    const form = useRef();
    const sendEmail = (e) => {
        // e.preventDefault();
    
        emailjs.sendForm('service_1kaixqo', 'template_an9v4z9', form.current, 'ZszqHqN4iAyjT5HUD')
          .then((result) => {
              console.log(result.text);
              console.log("Email sent");
          }, (error) => {
              console.log(error.text);
              console.log("Email not sent");
          });
    };

    return(
        <div className="card-cointainer">
            <Card css={{w: "100%"}}>
                <Card.Image
                    src={image}
                    width="100%"
                    height={340}
                    objectFit="cover"
                    alt="Card image background"
                />
            </Card>
            <div>
                <div className="heading">
                    <h1 className="card-title">{title} </h1>
                    <h2 className="room" style={{textAlign:"center" }}>{price}</h2>
                </div>
                <p className="card-description">
                    {description.map((data) => <li>{data.title}</li>)}
                </p>
                
                <div className="read-more-btn">
                    <div>
                        
                        <button className="read" onClick={handler}>
                            Book Now
                        </button>
                        
                        <Modal
                            closeButton
                            blur
                            aria-labelledby="modal-title"
                            open={visible}
                            onClose={closeHandler}
                        >
                            <Modal.Header>
                                <Text id="modal-title" size={18}>
                                    Welcome to Panchtatva
                                </Text>
                            </Modal.Header>
                            <form ref={form} onSubmit={sendEmail}>
                            <Modal.Body>
                                <Grid>
                                    <Input
                                        label="First Name"
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                    />
                                    <Input
                                        label="Last Name"
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                    />
                                </Grid>
                                <Grid>
                                    <Input
                                        label="Phone Number"
                                        type="text"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                    />
                                </Grid>
                                <Grid>
                                    <Input
                                        type="email"
                                        label="Email"
                                        id="email"
                                        name="email"
                                    />
                                </Grid>
                                <Grid>
                                    <Input
                                        label="Check in"
                                        type="date"
                                        min={currentDate} // Set the minimum selectable date as the current date
                                        id="checkIn"
                                        name="checkIn"
                                    />
                                    <Input
                                        label="Check out"
                                        type="date"
                                        min={currentDate} // Set the minimum selectable date as the current date
                                        id="checkOut"
                                        name="checkOut"
                                    />
                                </Grid>
                                <Grid>
                                    <Input type="number" label="No. of People" id="numberOfPeople" name="numberOfPeople"/>
                                </Grid>
                            </Modal.Body>
                            </form>
                            
                            <Modal.Footer>
                                <Button auto flat color="error" onPress={closeHandler} style={{width:"5rem"}}>
                                    Close
                                </Button>
                                <Button auto flat onPress={sendEmail} onClick={closeHandler} style={{width:"10rem"}} value={send}>
                                    Send Enquiry
                                </Button>
                            </Modal.Footer>
                          
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card2;

