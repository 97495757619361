import React from "react";
import "./camps.css";

const Camps = () => {
	return (
		<section className="bg-custom-background bg-cover bg-center">
			<div className="mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:px-8">
				<div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
					<div className="lg:col-span-2 lg:py-12"></div>
					<div className="rounded-lg bg-white/70  p-8 shadow-lg lg:col-span-3 lg:p-12">
						<h3 className="text-2xl camps-title">Camps</h3>
						<p className="para">
							High Adventure Camp Manali, nestled in Naggar's apple orchards amidst the Himalayas, offers a sanctuary for adventurers.With a range of activities from trekking to rafting, comfortable accommodations, locally sourced meals, and a community of outdoor enthusiasts.
						</p>
						<a className="btn" href="/campspage">
							See more
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Camps;
