import React from "react";
import { useRef } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { FiX } from "react-icons/fi";
import images from "../../constants/images";
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";
import { Modal, Input, Grid, Button } from "@nextui-org/react";
import { Text } from "@nextui-org/react";
import emailjs, { send } from "@emailjs/browser";

import { HashLink } from "react-router-hash-link";

const Navbar = () => {
  const location = useLocation();
  const homepageActive = location.pathname === "/";
  const aboutUsActive = location.pathname === "/about-us"; // Check if the current page is the About Us page
  const packagespageActive = location.pathname === "/#packages";
  const roomspageActive = location.pathname === "/#room";
  const gallerypageActive = location.pathname === "/#gallery";
  const contactusActive = location.pathname === "/#contactus";

  const currentDate = new Date().toISOString().split("T")[0]; // Get the current date in YYYY-MM-DD format

  const [toggleMenu, setToggleMenu] = React.useState(false);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setToggleMenu(false); // Close the menu after scrolling
    }
  };

  const [visible, setVisible] = React.useState(false);
  const handler = () => setVisible(true);
  const closeHandler = () => {
    setVisible(false);
    console.log("closed");
  };

  const form = useRef();
  const sendEmail = (e) => {
    // e.preventDefault();

    emailjs
      .sendForm(
        "service_1kaixqo",
        "template_an9v4z9",
        form.current,
        "ZszqHqN4iAyjT5HUD"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("Email sent");
        },
        (error) => {
          console.log(error.text);
          console.log("Email not sent");
        }
      );
  };

  const sections = document.querySelectorAll("div");
  const navLi = document.querySelectorAll("nav ul li");

  window.onscroll = () => {
    var current = "";

    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      if (window.scrollY >= sectionTop) {
        current = section.getAttribute("id");
      }
    });

    navLi.forEach((li) => {
      li.classList.remove("active");
      if (li.classList.contains(current)) {
        li.classList.add("active");
      }
    });
  };

  return (
    <nav
      className="app__navbar"
      style={{ position: "fixed", zIndex: "8", height: "80px" }}
    >
      <Link
        to={"/"}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        <div className="app__navbar-logo">
          <img src={images.logo} alt="app__logo" />
        </div>
      </Link>
      <ul className="app__navbar-links">
        <li className={`p__opensans ${homepageActive ? "active" : ""}`}>
          <a href="/">Home</a>
        </li>
        <li className={`p__opensans ${aboutUsActive ? "active" : ""}`}>
          <Link
            rel="preload"
            as="fetch"
            to="/about-us"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            About Us
          </Link>
        </li>

        <li className={`p__opensans ${packagespageActive ? "active" : ""}`}>
          <HashLink to="/#packages">Packages</HashLink>
        </li>

        <li className={`p__opensans ${roomspageActive ? "active" : ""}`}>
          <HashLink to="/#room">Rooms</HashLink>
        </li>
        <li className={`p__opensans ${roomspageActive ? "active" : ""}`}>
          <Link to="/campspage" onClick={() => {window.scrollTo({top : 0, behavior: 'smooth'})}}>Camps</Link>
        </li>

        <li className={`p__opensans ${gallerypageActive ? "active" : ""}`}>
          <Link
            rel="preload"
            as="fetch"
            to="/gallery"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Gallery
          </Link>
        </li>
        <li className={`p__opensans ${contactusActive ? "active" : ""}`}>
          <a href="#contactus" onClick={() => scrollToSection("contactus")}>
            Contact Us
          </a>
        </li>
      </ul>
      <div>
        <div className="app__navbar-login">
          <button className="p__opensans" onClick={handler}>
            Book Now
          </button>
        </div>
        <Modal
          closeButton
          blur
          aria-labelledby="modal-title"
          open={visible}
          onClose={closeHandler}
        >
          <Modal.Header>
            <Text id="modal-title" size={18}>
              Welcome to Panchtatva
            </Text>
          </Modal.Header>
          <form ref={form} onSubmit={sendEmail}>
            <Modal.Body>
              <Grid>
                <Input
                  label="First Name"
                  type="text"
                  id="firstName"
                  name="firstName"
                />
                <Input
                  label="Last Name"
                  type="text"
                  id="lastName"
                  name="lastName"
                />
              </Grid>
              <Grid>
                <Input
                  label="Phone Number"
                  type="number"
                  id="phoneNumber"
                  name="phoneNumber"
                />
              </Grid>
              <Grid>
                <Input type="email" label="Email" id="email" name="email" />
              </Grid>
              <Grid>
                <Input
                  // width="186px"
                  label="Check in"
                  type="date"
                  min={currentDate} // Set the minimum selectable date as the current date
                  id="checkIn"
                  name="checkIn"
                />
                <Input
                  // width="186px"
                  label="Check out"
                  type="date"
                  min={currentDate} // Set the minimum selectable date as the current date
                  id="checkOut"
                  name="checkOut"
                />
              </Grid>
              <Grid>
                <Input
                  type="number"
                  label="No. of People"
                  id="numberOfPeople"
                  name="numberOfPeople"
                />
              </Grid>
            </Modal.Body>
          </form>
          <Modal.Footer>
            <Button
              auto
              flat
              color="error"
              onPress={closeHandler}
              style={{ width: "5rem" }}
            >
              Close
            </Button>
            <Button
              auto
              flat
              onPress={sendEmail}
              onClick={closeHandler}
              style={{ width: "10rem" }}
              value={send}
            >
              Send Enquiry
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="#9d782a"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <FiX
              fontSize={27}
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
            />
            <ul className="app__navbar-smallscreen_links">
              <li>
                <a href="/" onClick={() => setToggleMenu(false)}>
                  Home
                </a>
              </li>
              <li>
                <Link
                  rel="preload"
                  as="fetch"
                  to="/about-us"
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    setToggleMenu(false);
                  }}
                >
                  About Us
                </Link>
              </li>
              <li>
                <HashLink
                  to="/#packages"
                  onClick={() => {
                    setToggleMenu(false);
                  }}
                >
                  Packages
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="/#room"
                  onClick={() => {
                    setToggleMenu(false);
                  }}
                >
                  Rooms
                </HashLink>
              </li>
              <li>
                <Link
                  to="/gallery"
                  onClick={() => {
                    setToggleMenu(false);
                  }}
                >
                  Gallery
                </Link>
              </li>
              <li>
                <Link 
                  to="/campspage"
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    setToggleMenu(false);
                  }}
                >
                  Camps
                </Link>
              </li>
              <li>
                <a href="#contactus" onClick={() => scrollToSection("contactus")}>
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
