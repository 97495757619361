import React from "react";
import images from "../../constants/images";

const Director = () => {
	return (
		<div className="text-gray-600 body-font ">
			<div className="container  sm:px-5 pb-10 mx-auto flex flex-col">
				<div className="lg:w-4/6 mx-auto">
					<div className="flex flex-col sm:flex-row mt-10">
						<div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
							<div className="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400 overflow-hidden scale-105">
								<img src={images.director} alt="phoebe" />
							</div>
							<div className="flex flex-col items-center text-center justify-center">
								<h2 className="font-medium title-font mt-4 text-gray-900 text-lg capitalize">
									Mr. Rajeev Sharma (Everester)
								</h2>
								<div className="w-12 h-1 bg-[#FD9D9D] rounded mt-2 mb-4"></div>
								<p className="text-base text-justify sm:text-center">
									Deputy Director (02-Aug-2007 To 31-Jul-2012)
									Atal Bihari Vajpayee Institute of
									Mountaineering and Allied Sports (ABVIMAS)
								</p>
							</div>
						</div>
						<div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0   text-justify sm:text-center">
							<p className="leading-relaxed text-base mb-4">
								Rajeev Sharma, a seasoned mountaineer, became
								the first Indian to conquer Mt. Aconcagua, the
								highest peak outside Asia. Having served at the
								Western Himalayas Mountaineering Institute (now
								ABVIMAS) since 1978, he retired as Deputy
								Director. He advised the 1993 Indian Everest
								Expedition and summited it himself. Rajeev
								boasts numerous first ascents on peaks like
								Lhotse, Kamet, and Mamostong Kangri, among
								others. His climbing feats span Karakoram,
								Nepal, Sikkim, Alps, and Andes. Additionally, he
								has skiing achievements in the Himalayas and
								French Alps. Rajeev holds prestigious
								mountaineering and skiing certifications from
								Chamonix, France, and UIAA.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Director;
