import images from "../constants/images";

// export const CONTACT_EMAIL_ID = 'sales@highadventure.in';

const Stress = [
	{
		imgUrl: images.award01,
		subtitle:
			"Our team of skilled Ayurvedic practitioners will guide you through personalized treatments and therapies to release stress, anxiety, and tension, fostering deep relaxation and tranquility.\n",
	},
	{
		imgUrl: images.award02,
		title: "Mental Clarity",
		subtitle:
			"Through yoga and meditation sessions in the midst of nature's beauty, you will learn techniques to calm the mind, enhance focus, and cultivate a sense of clarity and mindfulness.\n",
	},
	{
		imgUrl: images.award03,
		title: "Physical Rejuvenation",
		subtitle:
			"Indulge in our Ayurvedic massages and spa treatments, which rejuvenate your body, improve blood circulation, relieve muscle tension, and restore vitality.",
	},
	{
		imgUrl: images.award05,
		title: "Inner Connection",
		subtitle:
			"Immerse yourself in the natural surroundings with guided nature walks, connecting with the serene environment to find solace and a deeper connection with yourself.",
	},
	{
		imgUrl: images.award05,
		title: "Cultural Immersion",
		subtitle:
			"Experience the vibrant local culture and traditions through local sightseeing and interactive sessions with the local community, fostering a sense of connection, exploration, and appreciation.",
	},
	{
		imgUrl: images.award02,
		title: "Nourishment & Detoxification",
		subtitle:
			"Savor our Ayurvedic drinks and indulge in organic, nutritious meals that support the body's natural detoxification process, promoting overall well-being.",
	},
];

const jabwemet = [
	{
		day : '1',
		title : "Delhi/Chandigarh to Manali 585km 10-12 hrs",
		description : "Arrival at Delhi/Chandigarh Airport/Railway station and get transferred to Manali. On the way visit Pandoh Dam, Kullu Valley. Arrival Manali Check in the hotel. O/N Hotel"
	},
	{
		day : '2',
		title : "Sightseeing in Manali with romantic open air lunch by the River Beas",
		description : "After breakfast visit the sightseeing of various places in and around Manali, Like Hadimba Devi temple, club house, Vashisth hot water spring, Tibetan monastery and famous eating and shoping zone of Manali Mall road, after that  proceed to  the beautiful, River beas makes a charming picnic spot. Slightly hidden away in the otherwise hilly terrain, the first sight of the river comes as quite a pleasant surprise to the visitor. Watching the water unexpectedly rushing from a cluster of rocks overlooking a wooden bridge is a sight to behold. Enjoy a delectable lunch with your loved one as you admire the gushing falls and sip away on a refreshing glass of wine. Lunch with (veg and non-veg options), transfers to and from the hotel are included. O/N Hotel. Note: Candle light dinner with private dining in the hotel with honeymoon inclusion "
	},
	{
		day : '3',
		title : 'Solang valley tour with personal photo shoot 20 photos',
		description : 'After Breakfast Excursion to the valley of adventure Solang valley which is known for the best adventure sports place of the Himachal Pradesh where you can do the paragliding from 14000 ft height and see the beauty of the valley from the sky and you can do there snow skiing, snow rafting, snow scooter (in winter only) and cable car etc. end of the day driver come back to the hotel. O/N Dinner under the star: Bathed in the glow of fairy lights and lanterns, in a secluded spot in the orchards at Taste of India Restaurant, youll find a table, and magic youve never felt before. A meal cooked to perfection, a bottle of champagne chilling by the side, and Beethoven and the stars at your service.'
	},
	{
		day : '4',
		title : 'Manali to Chandigarh/Delhi',
		description : 'After breakfast check out from the hotel, and proceed to Chandigarh/Delhi by car and drop at your destination tour ends with memorable honeymoon trip to Manali with HighAdventure.'
	}
]

const jwmInclusions =[
	{
		title : 'Private transfer by ac car'
	},
	{
		title : 'Daily Morning Tea, Breakfast  & Dinner'
	},
	{
		title : 'All sightseeing and excursions as per the itinerary',
	},
	{
		title : 'Mentioned or similar hotels on as per the meal Plan'
	},
	{
		title : 'Romantic open air lunch by the river'
	},
	{
		title : 'Dinner under the star with transfer'
	},
	{
		title : 'Honeymoon inclusion with candle light dinner'
	},
	{
		title : 'Personal photoshoot in Manali'
	}
]

const jwmExclusions = [
	{
		title : 'Personal expenses of any nature.'
	},
	{
		title : 'Train/Air Fare'
	},
	{
		title : 'Guide'
	},
	{
		title : 'Entry Charges'
	},
	{
		title : 'Heater Charges'
	},
	{
		title : 'GST @ 5%'
	},
	{
		title : 'Unforeseen Expenses because of the weather conditions'
	},
	{
		title : 'Excursion to Rohtang Pass (Guest Own on direct payment & Subject to Permit)'
	},
	{
		title : 'Any up gradation in Airline class or hotel room category'
	},
	{
		title : 'Cost of Insurance'
	},
	{
		title : 'Any extra expense such as route change, Date change, Accommodation facilities, etc incurred due to the unforeseen, unavoidable forced majeure circumstances during the tour'
	},
	{
		title : 'Any services or activity charges other than those included in the tour itinerary/ Holiday Package Offer'
	}
]

const waytohimalayas = [
	{
		day: '1',
		title: 'Delhi to Manali 585km 12-14 hrs overnight journey by Ac Volvo',
		description: 'Around 5pm arrival at Delhi Volvo station (Majnu ka Tila/Kashmiri gate) and get your Volvo to Manali, 6pm start your Journey from Delhi to Manali, around 10pm will stop for the dinner at restaurant where you can have the dinner at your own, after dinner proceed to Manali, in the morning 8-10 am arrival at Manali Volvo stand where you will meet our driver who will take you to the hotel'
	},
	{
		day: '2',
		title: 'Manali & Local sightseeing (Half day)',
		description: 'In the morning arrival at Manali and transfer to the hotel, note: check in time will be 12:00pm so you have to wait at hotel reception according to room availability, afternoon the sightseeing of various places in and around Manali, Like Hadimba Devi temple, club house, Vashisth hot water spring, Tibetan monastery and famous eating and shoping zone of Manali Mall road, after that drive come back to the hotel. O/N'
	},
	{
		day: '3',
		title: 'Manali Solang valley tour (Adventure Day)',
		description: 'After Breakfast Excursion to the valley of adventure Solang valley which is known for the best adventure sports place of the Himachal Pradesh where you can do the paragliding from 14000 ft height and see the beauty of the valley from the sky and you can do there snow skiing, snow rafting, snow scooter (in winter only) and cable car etc. end of the day driver come back to the hotel. O/N '
	},
	{
		day: '4',
		title: 'Kullu Naggar sightseeing & transfer to Dalhi by Volvo',
		description: 'After breakfast check out from the hotel, and proceed to Kullu sightseeing where you can take the experience of water adventure sports like River rafting at Beas river, after visit Naggar castle, Rorich art gallery and evening come back to Manali Volvo stand, 4pm leave from Manali to Delhi by Volvo overnight journey, next day morning Volvo will drop you at Delhi (Kashmiri gate/Volvo stand) and proceed to your destination by your own tour ends with memorable trip to Manali with HighAdventure'
	}
]

const wayInclusions = [
	{
		title: 'Delhi to Manali & Manali to Delhi Volvo seats'
	},
	{
		title: 'Private transportation in Manali'
	},
	{
		title: 'Daily Morning Tea, Breakfast & Dinner'
	},
	{
		title: 'All sightseeing and excursions as per the itinerary'
	},
	{
		title: 'Mentioned or similar hotels on as per the meal Plan'
	},
	{
		title: 'Children under the age of 5 years stay complimentary at our hotel, without requiring an extra bed'
	},
]

const wayExclusions = [
	{
		title: 'Personal expenses of any nature'
	},
	{
		title: 'Train/Air fare'
	},
	{
		title: 'Guide'
	},
	{
		title: 'Entry Charges'
	},
	{
		title: 'Heater Charges'
	},
	{
		title: 'GST @ 5%'
	},
	{
		title: 'Unforeseen Expenses because of the weather conditions'
	},
	{
		title: 'Excursion to Rohtang Pass (Guest Own on direct payment & Subject to Permit)'
	},
	{
		title: 'Any up gradation in Airline class or hotel room category'
	},
	{
		title: 'Cost of Insurance'
	},
	{
		title: 'Any extra expense such as route change, Date change, Accommodation facilities, etc incurred due to the unforeseen, unavoidable forced major circumstances during the tour'
	},
	{
		title: 'Any services or activity charges other than those included in the tour itinerary / Holiday Package Offer'
	},
]

const znmdInclusions = [
	{
		title: 'Accommodation on twin sharing at Panchtatva resort'
	},
	{
		title: 'Daily breakfast and dinner'
	},
	{
		title: 'Transfers and sightseeing tours in a comfortable vehicle'
	},
	{
		title: 'Professional guides for adventure activities such as Zipline, Cycling, zorbing ball, ATV bike, River rafting 7 kms'
	},
	{
		title: 'Permits and entry fees for all included activities and destinations'
	},
]

const znmdExclusions = [
	{
		title: 'Airfare or train fare to and from Manali'
	},
	{
		title: 'Lunch and any meals not mentioned in the itinerary'
	},
	{
		title: 'Personal expenses and tips'
	},
	{
		title: 'Travel insurance'
	},
	{
		title: 'Any other items not specified in the inclusions'
	},
]

const zindaginamilegidobara = [
	{
		day:'1',
		title: 'Arrival and Local Exploration',
		description: 'Arrival in Manali and get transfer to your hotel, check in the hotel, afternoon visit Naggar castle and Roerich art gallery , and museum etc. evening come back to the hotel. Dinner in the hotel.'
	},
	{
		day:'2',
		title: 'Solang Valley Adventures and Atal tunnel',
		description: 'After breakfast proceed to Atal tunnel and visit sissu lake etc. Afternoon come to the Solang valley for adventure activities like Zipline, zorbing ball, ATV ride etc. Note: you can do the paragliding and ropeway also in Solang Valley on extra cost. Evening come back to the hotel. Dinner in the hotel.'
	},
	{
		day:'3',
		title: 'Cycling downhill and river rafting adventure',
		description: 'Today after breakfast we will go for the thrilling adventure Cycling downhill from Jana waterfall to Naggar 16 kms cycling through the tall trees amidst the nature with the beautiful view of Kullu valley and snow capped mountains . Lunch at Haripur at tour fish farm on your own expense. Afternoon drive to Babeli for white water river rafting. Evening come back to the hotel. Dinner in the hotel.'
	},
	{
		day:'4',
		title: 'Manali city and shopping',
		description: 'Breakfast in the hotel and transfer to Manali city for shopping at Mall road Manali , visit Hadimba Devi temple, Old Manali, Vashisht temple, Tibetan monastery, and van vihar etc. Dinner in the hotel.'
	},
	{
		day:'4',
		title: 'Manali city and shopping',
		description: 'Breakfast in the hotel and transfer to Manali city for shopping at Mall road Manali , visit Hadimba Devi temple, Old Manali, Vashisht temple, Tibetan monastery, and van vihar etc. Dinner in the hotel.'
	},
	{
		day:'5',
		title: 'Departure',
		description: 'Check-Out from the Hotel: Bid farewell to Manali as you head to your onward destination. Book again with us hooray'
	},
]

const znmdGallery = [
	{
		imgUrl: images.znmd1
	},
	{
		imgUrl: images.znmd2
	},
	{
		imgUrl: images.znmd3
	},
	{
		imgUrl: images.znmd4
	}
]

const wayGallery = [
	{
		imgUrl: images.way2
	},
	{
		imgUrl: images.way3
	},
	{
		imgUrl: images.way4
	},
	{
		imgUrl: images.way5
	}
]

const jwmGallery = [
	{
		imgUrl: images.jabwemet
	},
	{
		imgUrl: images.jabwemet2
	},
	{
		imgUrl: images.jabwemet3
	},
	{
		imgUrl: images.jabwemet4
	}
]

const Weight = [
	{
		imgUrl: images.award01,
		title: "Healthy Weight Loss",
		subtitle:
			"Our expert Ayurvedic practitioners will create personalized programs tailored to your unique needs, promoting healthy and sustainable weight loss through a blend of Ayurvedic treatments, targeted massages, and mindful practices.\n",
	},
	{
		imgUrl: images.award02,
		title: "Detoxification",
		subtitle:
			"Purify and rejuvenate your body with Ayurvedic detox therapies that help eliminate toxins, enhance digestion, and improve metabolism, laying a foundation for effective weight management.\n",
	},
	{
		imgUrl: images.award03,
		title: "Balanced Nutrition",
		subtitle:
			"Our experienced nutritionists will design customized diet plans based on your body type and requirements, incorporating nourishing and delicious organic meals that support your weight loss goals.\n",
	},
	{
		imgUrl: images.award05,
		title: "Fitness and Exercise",
		subtitle:
			"Engage in invigorating yoga and exercise sessions that enhance your physical strength, flexibility, and stamina, supporting your weight management journey.\n.",
	},
	{
		imgUrl: images.award05,
		title: "Mindful Practices",
		subtitle:
			"Learn mindfulness techniques, meditation, and breathing exercises that cultivate a positive mindset, emotional balance, and healthy habits, empowering you to make mindful choices for long-term weight management.",
	},
	{
		imgUrl: images.award02,
		title: "Nature Immersion",
		subtitle:
			"Immerse yourself in the serene beauty of Manali through guided nature walks, connecting with the natural environment to boost your energy, reduce stress, and enhance overall well-being.",
	},
];

const Rejuvenation = [
	{
		imgUrl: images.award01,
		title: "Cellular Rejuvenation",
		subtitle:
			"Experience the rejuvenating effects of specialized Ayurvedic treatments and therapies that promote cellular regeneration, slow down the aging process, and restore vitality from within.\n",
	},
	{
		imgUrl: images.award02,
		title: "Stress Relief and Relaxation",
		subtitle:
			"Indulge in soothing massages, relaxation techniques, and mindfulness practices that help release tension, reduce stress, and foster deep relaxation.\n",
	},
	{
		imgUrl: images.award03,
		title: "Enhanced Energy and Vitality",
		subtitle:
			"Our holistic approach revitalizes your energy levels, boosts stamina, and enhances overall vitality, allowing you to embrace life with renewed vigor.\n",
	},
	{
		imgUrl: images.award05,
		title: "Mental Clarity and Emotional Balance",
		subtitle:
			"Through yoga, meditation, and Ayurvedic practices, you will cultivate mental clarity, reduce emotional imbalances, and experience a renewed sense of inner peace.\n.",
	},
	{
		imgUrl: images.award05,
		title: "Skin Nourishment and Radiance",
		subtitle:
			"Nourish your skin with specialized Ayurvedic beauty treatments, herbal facials, and natural skincare rituals that leave your skin glowing, rejuvenated, and radiant.",
	},
	{
		imgUrl: images.award02,
		title: "Lifestyle Guidance",
		subtitle:
			"Benefit from personalized wellness consultations and guidance from our Ayurvedic experts, who will provide recommendations for maintaining balance and rejuvenation in your daily life.",
	},
];

const Immunity = [
	{
		imgUrl: images.award01,
		title: "Enhanced Immune Function",
		subtitle:
			"Our expert Ayurvedic practitioners will create personalized programs tailored to strengthen and support your immune system, utilizing Ayurvedic therapies, immune-boosting treatments, and herbal remedies.\n",
	},
	{
		imgUrl: images.award02,
		title: "Stress Reduction",
		subtitle:
			"Chronic stress can weaken the immune system. Our retreat provides relaxation techniques, mindfulness practices, and stress management strategies to reduce stress and promote a balanced immune response.\n",
	},
	{
		imgUrl: images.award03,
		title: "Optimal Nutrition",
		subtitle:
			"Our experienced nutritionists will design personalized dietary plans rich in immune-boosting nutrients, incorporating organic, locally sourced ingredients that support immune function and overall well-being.\n",
	},
	{
		imgUrl: images.award05,
		title: "Detoxification",
		subtitle:
			"Ayurvedic detox therapies will help eliminate toxins, cleanse the body, and enhance the immune system's efficiency by removing barriers to optimal health.\n.",
	},
	{
		imgUrl: images.award05,
		title: "Physical Fitness",
		subtitle:
			"Engage in rejuvenating yoga, gentle exercises, and invigorating activities that support physical fitness and strengthen the body's defense mechanisms.",
	},
	{
		imgUrl: images.award02,
		title: "Mental and Emotional Wellness",
		subtitle:
			"Mindfulness practices, meditation, and breathing exercises will be incorporated to reduce stress, boost mental clarity, and enhance emotional well-being, all of which contribute to a robust immune system.\n",
	},
	{
		imgUrl: images.award02,
		title: "Nature Immersion",
		subtitle:
			"Immerse yourself in the serene beauty of Manali through guided nature walks, connecting with the natural environment to boost your energy, reduce stress, and enhance overall well-being.\n",
	},
];

const Pain = [
	{
		imgUrl: images.award01,
		title: "Targeted Pain Relief",
		subtitle:
			"Our experienced Ayurvedic practitioners will develop personalized treatment plans to address your specific pain concerns, targeting the root cause of pain and providing relief through specialized Ayurvedic therapies.\n",
	},
	{
		imgUrl: images.award02,
		title: "Natural Pain Management",
		subtitle:
			"Experience the benefits of Ayurvedic herbs, oils, and natural remedies that possess analgesic and anti-inflammatory properties, reducing pain and promoting healing without the use of harsh medications.\n",
	},
	{
		imgUrl: images.award03,
		title: "Improved Mobility and Flexibility",
		subtitle:
			"Through gentle exercises, therapeutic yoga, and targeted stretches, you will enhance your mobility, improve flexibility, and reduce stiffness, allowing for greater freedom of movement.\n",
	},
	{
		imgUrl: images.award05,
		title: "Stress Reduction",
		subtitle:
			"Chronic pain can lead to emotional stress and tension. Our retreat incorporates relaxation techniques, mindfulness practices, and stress management strategies to alleviate stress, promoting pain relief and overall well-being.\n.",
	},
	{
		imgUrl: images.award05,
		title: "Enhancing Quality of Life",
		subtitle:
			"By managing pain effectively, you will experience improved sleep, increased energy levels, and an overall enhancement in the quality of your daily life.",
	},
	{
		imgUrl: images.award02,
		title: "Lifestyle Modifications",
		subtitle:
			"Our team will provide guidance on lifestyle modifications, including nutrition advice, exercise recommendations, and self-care practices that support pain management and long-term well-being.\n",
	},
];

const Ayurvedic = [
	{
		imgUrl: images.award01,
		title: "Deep Detoxification",
		subtitle:
			"Experience the power of Panchakarma, a holistic system of detoxification, to remove accumulated toxins, purify your body's tissues, and restore balance to your entire being.\n",
	},
	{
		imgUrl: images.award02,
		title: "Improved Digestion and Metabolism",
		subtitle:
			"Panchakarma therapies and specialized Ayurvedic treatments help restore digestive health, improve metabolism, and optimize the functioning of your digestive system.\n",
	},
	{
		imgUrl: images.award03,
		title: "Enhanced Energy and Vitality",
		subtitle:
			"As toxins are eliminated and energy channels are cleared, you will experience increased energy levels, improved vitality, and a renewed zest for life.\n",
	},
	{
		imgUrl: images.award05,
		title: "Stress Relief and Relaxation",
		subtitle:
			"Panchakarma treatments and relaxation techniques soothe the nervous system, release accumulated stress, and promote deep relaxation, allowing you to experience profound rejuvenation and inner peace.\n.",
	},
	{
		imgUrl: images.award05,
		title: "Mental Clarity and Emotional Balance",
		subtitle:
			"Through yoga, meditation, and Ayurvedic practices, you will cultivate mental clarity, reduce emotional imbalances, and experience a renewed sense of harmony and well-being.",
	},
	{
		imgUrl: images.award02,
		title: "Enhanced Skin Health",
		subtitle:
			"Panchakarma therapies and Ayurvedic treatments nourish and rejuvenate your skin, promoting a radiant complexion, improved texture, and a youthful glow.\n",
	},
	{
		imgUrl: images.award02,
		title: "Improved Sleep Quality",
		subtitle:
			"As your body and mind find balance and harmony, you will experience improved sleep patterns, deep restful sleep, and a sense of rejuvenation upon waking.\n",
	},
];

// who can benefit
const StressRecommended = [
	{
		imgUrl: images.award01,
		title: "Busy Professionals",
		subtitle:
			"If you find yourself overwhelmed by the demands of work and life, our retreat offers an opportunity to recharge, rejuvenate, and find balance amidst the serene mountains.\n",
	},
	{
		imgUrl: images.award02,
		title: "Stressed Individuals",
		subtitle:
			"Whether you're experiencing stress-related symptoms, anxiety, or burnout, our holistic approach will provide you with tools and practices to manage stress and restore equilibrium.\n",
	},
	{
		imgUrl: images.award03,
		title: "Nature Enthusiasts",
		subtitle:
			"If you seek solace and rejuvenation in nature, our retreat combines the healing power of Ayurveda with the therapeutic benefits of spending time amidst the natural beauty of Manali.",
	},
	{
		imgUrl: images.award05,
		title: "Soul Seekers",
		subtitle:
			" If you yearn for deeper self-reflection, connection, and a break from the fast-paced world, our retreat offers a nurturing environment for inner exploration, self-care, and transformation.\n",
	},
	{
		imgUrl: images.award05,
		title: "Travelers Seeking Cultural Experiences",
		subtitle:
			"Experience the vibrant local culture and traditions through local sightseeing and interactive sessions with the local community, fostering a sense of connection, exploration, and appreciation.",
	},
];

const WeightRecommended = [
	{
		imgUrl: images.award01,
		title: "Weight-conscious Individuals",
		subtitle:
			"If you are looking to achieve and maintain a healthy weight, our retreat provides a supportive and nurturing environment where you can receive personalized guidance and sustainable strategies for weight management.\n",
	},
	{
		imgUrl: images.award02,
		title: "Health Seekers",
		subtitle:
			"Whether you want to improve your overall health or address specific health concerns related to weight, our holistic approach integrates Ayurveda, nutrition, and mindfulness to help you achieve optimal well-being.\n",
	},
	{
		imgUrl: images.award03,
		title: "Individuals with Lifestyle Challenges",
		subtitle:
			"If your lifestyle choices have led to weight gain or difficulties in weight management, our retreat offers a transformative experience, empowering you to make positive changes and adopt a healthier lifestyle.",
	},
	{
		imgUrl: images.award05,
		title: "Fitness Enthusiasts",
		subtitle:
			" If you seek a comprehensive program that combines fitness, wellness, and natural beauty, our retreat provides the perfect opportunity to enhance your fitness goals while addressing weight management.\n",
	},
	{
		imgUrl: images.award05,
		title: "Individuals Seeking Long-Term Solutions",
		subtitle:
			"If you desire sustainable weight loss and want to develop healthy habits that go beyond quick fixes, our retreat focuses on empowering you with the knowledge, tools, and practices needed for long-term success.",
	},
];

const RejunvenationRecommended = [
	{
		imgUrl: images.award01,
		title: "Individuals Seeking Stress Relief",
		subtitle:
			"If you are looking to escape the pressures of daily life and find relaxation, our retreat offers a serene sanctuary to unwind, rejuvenate, and restore balance to your being.\n",
	},
	{
		imgUrl: images.award02,
		title: "Health Enthusiasts",
		subtitle:
			"Whether you prioritize self-care or want to enhance your overall well-being, our rejuvenation retreat provides a comprehensive approach to rejuvenation and invigoration.\n",
	},
	{
		imgUrl: images.award03,
		title: "Individuals in Need of Emotional Healing",
		subtitle:
			"If you are experiencing emotional imbalances, anxiety, or burnout, our holistic approach can help you find emotional harmony, inner peace, and a renewed zest for life.",
	},
	{
		imgUrl: images.award05,
		title: "Those Seeking Anti-aging Solutions",
		subtitle:
			"If you desire to slow down the signs of aging and maintain a youthful appearance, our rejuvenation retreat offers specialized Ayurvedic treatments and practices for radiant skin and a youthful glow.\n",
	},
	{
		imgUrl: images.award05,
		title: "Individuals in Need of Mental Clarity",
		subtitle:
			"If you seek mental clarity, improved focus, and enhanced cognitive function, our retreat integrates mindfulness practices and meditation techniques to sharpen the mind and foster mental well-being.",
	},
	{
		imgUrl: images.award05,
		title: "Anyone Looking for a Wellness Escape",
		subtitle:
			"If you simply want to indulge in a rejuvenating getaway, surrounded by the beauty of nature and guided by Ayurvedic principles, our retreat provides a holistic wellness experience.",
	},
];

const ImmunityRecommended = [
	{
		imgUrl: images.award01,
		title: "Individuals with Weakened Immune Systems",
		subtitle:
			"If you are prone to frequent illnesses or have a weakened immune system, our retreat provides targeted immune-boosting strategies to strengthen your body's defenses.\n",
	},
	{
		imgUrl: images.award02,
		title: "Health-conscious Individuals",
		subtitle:
			"Whether you want to enhance your overall well-being or take proactive measures to maintain optimal health, our immunity booster retreat offers comprehensive immune support.\n",
	},
	{
		imgUrl: images.award03,
		title: "Individuals Recovering from Illness",
		subtitle:
			"If you have recently recovered from an illness and want to strengthen your immune system to prevent future health issues, our retreat provides a supportive environment for post-illness recovery and immune enhancement.",
	},
	{
		imgUrl: images.award05,
		title: "Those with High-Stress Lifestyles",
		subtitle:
			"If you lead a high-stress lifestyle that compromises your immune system, our retreat offers techniques and practices to manage stress, strengthen your body's defenses, and restore balance.\n",
	},
	{
		imgUrl: images.award05,
		title: "Individuals Seeking Preventive Health Measures",
		subtitle:
			"If you prioritize preventive health and want to strengthen your immune system to ward off illnesses, our retreat provides a proactive approach to immune system enhancement.",
	},
	{
		imgUrl: images.award05,
		title: "Anyone Looking for a Wellness Getaway",
		subtitle:
			"If you simply want to escape the hustle and bustle of daily life and immerse yourself in a rejuvenating environment that supports immune health, our retreat offers a holistic wellness experience.",
	},
];

const PainRecommended = [
	{
		imgUrl: images.award01,
		title: " Individuals with Chronic Pain",
		subtitle:
			"If you suffer from chronic pain conditions such as arthritis, fibromyalgia, back pain, or joint pain, our retreat offers targeted pain management strategies to alleviate your discomfort and improve your quality of life.\n",
	},
	{
		imgUrl: images.award02,
		title: "Post-Injury Recovery",
		subtitle:
			"If you are recovering from an injury or surgery and experiencing persistent pain, our retreat provides a supportive environment for pain relief, healing, and rehabilitation.\n",
	},
	{
		imgUrl: images.award03,
		title: "Individuals with Musculoskeletal Issues",
		subtitle:
			" If you have musculoskeletal conditions or disorders affecting your bones, muscles, or joints, our pain management retreat offers specialized treatments and therapies to address your specific needs.",
	},
	{
		imgUrl: images.award05,
		title: "Those Seeking Non-invasive Pain Relief",
		subtitle:
			"If you prefer natural, non-invasive approaches to pain management and want to avoid excessive reliance on medications, our retreat provides alternative modalities rooted in Ayurvedic principles.\n",
	},
	{
		imgUrl: images.award05,
		title: "Individuals Seeking Holistic Well-being",
		subtitle:
			"If you are looking for a holistic approach to pain management that encompasses physical, mental, and emotional well-being, our retreat combines Ayurveda, relaxation techniques, and mindfulness practices to support your overall healing journey.\n\n",
	},
	{
		imgUrl: images.award05,
		title: "Anyone looking for a Healing Getaway",
		subtitle:
			"If you simply desire a peaceful sanctuary to escape from pain, rejuvenate your body, and restore your vitality, our pain management retreat offers a serene environment and expert care.\n",
	},
];

const AyurvedicRecommended = [
	{
		imgUrl: images.award01,
		title: "Individuals Seeking Detoxification",
		subtitle:
			"If you are looking to purify your body, eliminate toxins, and revitalize your system, our Panchakarma retreat offers a profound detoxification experience.\n",
	},
	{
		imgUrl: images.award02,
		title: "Health Enthusiasts",
		subtitle:
			"Whether you prioritize preventive health or want to enhance your overall well-being, our detoxification retreat provides a comprehensive approach to rejuvenation and optimal health.\n",
	},
	{
		imgUrl: images.award03,
		title: "Those in Need of Stress Relief",
		subtitle:
			"If you are experiencing stress, anxiety, or burnout, our retreat offers a serene sanctuary to release tension, find relaxation, and restore balance to your mind and body.",
	},
	{
		imgUrl: images.award05,
		title: "Individuals Seeking Emotional Healing",
		subtitle:
			"If you are facing emotional imbalances, mood swings, or a sense of disconnection, our retreat provides a supportive environment for emotional healing, rejuvenation, and inner transformation.",
	},
	{
		imgUrl: images.award05,
		title: "Individuals in Need of Skin Rejuvenation",
		subtitle:
			" If you desire to improve your skin health, address skin concerns, and achieve a radiant complexion, our Panchakarma retreat incorporates specialized Ayurvedic beauty treatments for skin rejuvenation.\n",
	},
	{
		imgUrl: images.award05,
		title: "Those Seeking Holistic Well-being",
		subtitle:
			"If you are looking for a comprehensive approach to well-being that encompasses physical, mental, and spiritual aspects, our retreat combines Ayurvedic practices, detoxification therapies, and rejuvenation techniques for a holistic experience.\n",
	},
	{
		imgUrl: images.award05,
		title: "Anyone Seeking a Detox Getaway",
		subtitle:
			"If you simply want to escape from the demands of everyday life and immerse yourself in a rejuvenating environment, our Panchakarma retreat offers a tranquil sanctuary for detoxification and relaxation.\n",
	},
];

// what it includes

const StressInclusion = [
	{
		title: "Shiro Vasti",
	},
	{
		title: "Aroma Theorapy",
	},
	{
		title: "Fitness Training",
	},
	{
		title: "Shirodhara",
	},
	{
		title: "Kati Vasti",
	},
	{
		title: "Abhyanga",
	},
];

const WeightInclusion = [
	{
		title: "Fitness Training",
	},
	{
		title: "Udvartana",
	},
	{
		title: "Shirodhara",
	},
	{
		title: "Abhyanga",
	},
];

const PainInclusion = [
	{
		title: "Shiro Vasti",
	},
	{
		title: "Griva Basti",
	},
	{
		title: "Postural Integration",
	},
	{
		title: "Janu Basti",
	},
	{
		title: "Kati Vasti",
	},
	{
		title: "Manual Theorapy Mobilization",
	},
];

const ImmunityInclusion = [
	{
		title: "Tan Lepa",
	},
	{
		title: "Shirodhara",
	},
	{
		title: "Janu Vasti",
	},
	{
		title: "Earth Stone Massage",
	},
	{
		title: "Abhyanga",
	},
];

const RejuvenationInclusion = [
	{
		title: "Jasmin salt scrub",
	},
	{
		title: "tan lepa",
	},
	{
		title: "Herbal Facial",
	},
	{
		title: "Earth stone massage",
	},
	{
		title: "Shirodhara",
	},
];

const AyurvedicInclusion = [
	{
		title: "Choorna Swedna",
	},
	{
		title: "Earth stone massage",
	},
	{
		title: "Salt scrub",
	},
	{
		title: "Abhyanga",
	},
	{
		title: "Kati Vasti",
	},
];

// rooms

const Prithvi = [
	{
		title: "Spacious room with a comfortable double bed.",
	},
	{
		title: "Garden or outdoor seating area for relaxation.",
	},
	{
		title: "Earthy décor and natural elements for a grounded ambiance.",
	},
	{
		title: "Tea and coffee making facilities.",
	},
	{
		title: "Complimentary bottled water.",
	},
	{
		title: "Wardrobe for storage.",
	},
	{
		title: "Daily housekeeping service.\n",
	},
	{
		title: "Wake-up call service for guests who wish to witness breathtaking sunrises or celestial events.\n",
	},
	{
		title: "High-speed Wi-Fi access.\n",
	},
];

const Vayu = [
	{
		title: "Luxurious room with a king-sized bed.\n",
	},
	{
		title: "Private balcony with panoramic views of the surrounding mountains or valley.\n",
	},
	{
		title: "Unique sky-view feature through a ceiling panel, allowing guests to gaze at the sky from the comfort of their room.\n",
	},
	{
		title: "Tea and coffee making facilities.\n",
	},
	{
		title: "Complimentary bottled water.\n",
	},
	{
		title: "Wardrobe for storage.\n",
	},
	{
		title: "Daily housekeeping service.\n",
	},
	{
		title: "Wake-up call service for guests who wish to witness breathtaking sunrises or celestial events.\n",
	},
	{
		title: "High-speed Wi-Fi access.\n",
	},
];

const Akash = [
	{
		title: "Spacious room with a comfortable double bed.",
	},
	{
		title: "Balcony or terrace offering scenic views and fresh air.",
	},
	{
		title: "Unique sky-view feature through a ceiling panel, allowing guests to gaze at the sky from the comfort of their room.\n",
	},
	{
		title: "Airy and light-filled ambiance.",
	},
	{
		title: "Tea and coffee making facilities.",
	},
	{
		title: "Complimentary bottled water.",
	},
	{
		title: "Wardrobe for storage.",
	},
	{
		title: "Daily housekeeping service.",
	},
	{
		title: "Wake-up call service for guests who wish to witness breathtaking sunrises or celestial events.",
	},
	{
		title: "High-speed Wi-Fi access.",
	},
];

// price

const wayPrice = [
	{
		day: "2 Adults",
		price: "₹21,999",
	},
	{
		day: "3 Adults",
		price: "₹27,499",
	},
];

const jwmPrice = [
	{
		day: "2 Adults",
		price: "₹39,999",
	},
];

const znmdPrice = [
	{
		day: "2 Adults",
		price: "₹35,999",
	},
	{
		day: "3 Adults",
		price: "₹45,999",
	},
]
export default {
	Stress,
	Weight,
	Rejuvenation,
	Immunity,
	Pain,
	Ayurvedic,
	StressRecommended,
	WeightRecommended,
	RejunvenationRecommended,
	ImmunityRecommended,
	PainRecommended,
	AyurvedicRecommended,
	StressInclusion,
	WeightInclusion,
	PainInclusion,
	ImmunityInclusion,
	RejuvenationInclusion,
	AyurvedicInclusion,
	Prithvi,
	Vayu,
	Akash,
	wayPrice,
	jwmPrice,
	znmdPrice,
	jabwemet,
	waytohimalayas,
	zindaginamilegidobara,
	wayInclusions,
	wayExclusions,
	jwmInclusions,
	jwmExclusions,
	znmdInclusions,
	znmdExclusions,
	jwmGallery,
	znmdGallery,
	wayGallery
};

// AboutUs Content

export const AboutContent = [
	{
		title: "Panchtatva Resort: Where Nature Meets Luxury",
		description:
			"Welcome to Panchtatva Resort, your serene sanctuary nestled in the heart of the picturesque Naggar Valley in Manali. Surrounded by the majestic Himalayas and lush greenery, our resort offers a harmonious blend of luxury, comfort, and tranquility, inviting you to unwind and reconnect with nature.As you step into our resort, you're greeted by the soothing melodies of the surrounding apple orchards and the crisp mountain air. Each of our meticulously designed rooms and suites is a cocoon of relaxation, featuring modern amenities and breathtaking views of the snow-capped peaks or the verdant valleys.Situated at a distance of 17km from the city, our resort provides easy access to essential services like police and hospital, ensuring peace of mind during your stay.",
		imgUrl: images.resortImg,
	},
	{
		title: "Welcome to Renest Hotels and Resorts",
		description:
			"As you step into our resort, your senses are greeted by the gentle caress of the morning sunshine filtering through your windows, illuminating the ethereal beauty of the surrounding landscape. The crisp mountain air carries with it the invigorating scent of organic farming, a testament to our commitment to sustainability and connection with the land.At night, as you retire to your room, be enchanted by the celestial spectacle unfolding above. With minimal light pollution, our resort offers a front-row seat to the cosmic ballet, where you can lose yourself in the mesmerizing dance of the stars from the comfort of your own sanctuary.",
		imgUrl: images.hotelimg,
	},
	{
		title: "Culinary Bliss: Locally Inspired Delights Await",
		description:
			"Indulge your senses with delectable culinary delights at our multi-cuisine restaurant, where every dish is crafted with fresh, locally sourced ingredients to tantalize your taste buds. Whether you're craving authentic Himachali cuisine or international flavors, our chefs promise a culinary journey that delights and satisfies.",
		imgUrl: images.food,
	},
	{
		title: "Panchtatva Resort: Nature’s Luxurious Retreat",
		description:
			"At Panchtatva Resort, we're committed to creating unforgettable experiences for our guests, whether you're seeking a romantic getaway, a family vacation, or a corporate retreat. Let us be your gateway to the magical charm of Manali, where every moment is infused with warmth, hospitality, and the essence of Panchtatva – the five elements of nature.Come, discover the true meaning of relaxation and rejuvenation amidst the pristine beauty of Naggar Valley. Your blissful retreat awaits at Panchtatva Resort, where nature meets luxury in perfect harmony.",
		imgUrl: images.fullImage,
	},
];
