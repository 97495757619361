import logo from "../assets/logo.webp";
import desktopVideo from "../assets/My Movie 1.webm";
import mobileVideo from "../assets/mobileP.webm";
import aboutP from "../assets/aboutP.jpg";
import spoon from "../assets/spoon.webp";
import home1 from "../assets/home1.jpg";
import home2 from "../assets/home2.jpg";
import home3 from "../assets/home3.jpg";
import gallery03 from "../assets/weight.webp";
import gallery12 from "../assets/gallery12.webp";
import gallery13 from "../assets/gallery13webp.webp";
import award01 from "../assets/award01.webp";
import award02 from "../assets/award02.webp";
import award03 from "../assets/award03.webp";
import award05 from "../assets/award05.webp";
import akash from "../assets/akash.jpeg";
import vayu from "../assets/vayu.jpg";
import prithvi from "../assets/prithvi.jpeg";
import meditationone from "../assets/meditationone.jpg";
import meditationtwo from "../assets/meditationtwo.jpg";
import home from "../assets/home.jpg";
import znmd from "../assets/znmd.jpg";
import jabwemet from "../assets/jabwemet.jpg";
import way from "../assets/way.jpg";
import about1 from "../assets/aboutP.jpg";
import food from "../assets/food.jpg";
import fullImage from "../assets/29.jpg";
import hotelimg from "../assets/2.jpg";
import resortImg from "../assets/11.jpg";
import awardSymbol from "../assets/award-symbol.png";
import nagarCastle from "../assets/nagar castelR.jpg";
import cycling from "../assets/cyclingR.jpg";
import fishing from "../assets/fishing.jpg";
import track from "../assets/souil track.jpg";
import paragliding from "../assets/paraglidingR.jpg";
import rafting from "../assets/raftingR.jpg";
import waterfall from "../assets/waterfallR.jpg";
import next from '../assets/next.png'
import previous from '../assets/previous.png'
import znmd1 from '../assets/znmd1.jpg'
import znmd2 from '../assets/znmd2.jpg'
import znmd3 from '../assets/znmd3.jpg'
import znmd4 from '../assets/znmd4.jpg'
import jabwemet1 from '../assets/jabwemet1.jpg'
import jabwemet2 from '../assets/jabwemet2.jpg'
import jabwemet3 from '../assets/jabwemet3.jpg'
import jabwemet4 from '../assets/jabwemet4.jpg'
import way1 from '../assets/way1.jpg'
import way2 from '../assets/way2.jpg'
import way3 from '../assets/way3.jpg'
import way4 from '../assets/way4.jpg'
import way5 from '../assets/way5.jpg'
import camp1 from "../assets/camp1.jpg";
import camp2 from "../assets/camp2.jpg";
import camp3 from "../assets/camp3.jpg";
import camp4 from "../assets/camp4.jpg";
import camp5 from "../assets/camp5.jpg";
import camp6 from "../assets/camp6.jpg";
import camp8 from "../assets/camp8.jpg";
import director from "../assets/director.png"
import checkList from "../assets/check-list.png"
import homegallery from "../assets/homegallery.jpg"

export default {
    logo,
    desktopVideo,
    mobileVideo,
    aboutP,
    spoon,
    gallery03,
    gallery12,
    gallery13,
    award01,
    meditationone,
    meditationtwo,
    award02,
    award03,
    award05,
    akash,
    vayu,
    prithvi,
    home,
    home1,
    home2,
    home3,
    znmd,
    znmd1,
    znmd2,
    znmd3,
    znmd4,
    jabwemet,
    jabwemet1,
    jabwemet2,
    jabwemet3,
    jabwemet4,
    way,
    way1,
    way2,
    way3,
    way4,
    way5,
    about1,
	camp1,
	camp2,
	camp3,
	camp4,
	camp5,
	camp6,
	camp8,
	director,
	checkList,
	food,
	fullImage,
	hotelimg,
	resortImg,
	awardSymbol,
	nagarCastle,
	cycling,
	fishing,
	track,
	paragliding,
	rafting,
	waterfall,
	next,
	previous,
    homegallery
}