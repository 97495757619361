import React from 'react';
import { Grid } from "@nextui-org/react";
import Card from "../../components/Pcard/Card";
import images from "../../constants/images";
import './Packages.css';
import { Link } from "react-router-dom";

export default function App() {
    return (
        <>
            <div className="package" id="packages">
                <div>
                    <h1 className="headtext">What we Offer</h1>
                </div>
                <Grid.Container gap={2} justify="center">
                    <Grid xs={12} sm={4}>
                        <Link rel="preload" as="fetch" to="/yeh-jawaani-hai-deewani" onClick={() => {
                        window.scrollTo({top: 0, behavior: 'smooth'});}} >
                            <Card image={images.way1} title="Yeh Jawaani Hai Deewani" duration="3Nights/4Days" information = "" price = "₹27,999 (2P)"/>
                        </Link>
                    </Grid>
                    <Grid xs={12} sm={4} onClick={() => {
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    }}>
                        <Link rel="preload" as="fetch" to="/jab-we-met-manali">
                            <Card image={images.jabwemet} title="Jab we met in Manali" duration="3Nights/4Days" information = "" price = "₹39,999 (2P)"/>
                        </Link>
                    </Grid>
                    <Grid xs={12} sm={4} onClick={() => {
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    }}>
                        <Link rel="preload" as="fetch" to="/zindagi-na-milegi-dobara">
                            <Card image={images.znmd} title="Zindagi Na Milegi Dobara" duration="4Nights/5Days" information= "" price = "₹64,999(2P)"/>
                        </Link>
                    </Grid>
                    {/* <Grid xs={12} sm={4} onClick={() => {
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    }}>
                        <Link rel="preload" as="fetch" to="/pain-management">
                            <Card image={images.pain} title="Pain Management Retreat ( वेदनाशमन )"/>
                        </Link>
                    </Grid>
                    <Grid xs={12} sm={4} onClick={() => {
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    }}>
                        <Link rel="preload" as="fetch" to="/Immunity-Booster">
                            <Card image={images.immunity} title="Immunity Booster Retreat ( वीर्योध्दारक )"/>
                        </Link>
                    </Grid>
                    <Grid xs={12} sm={4} onClick={() => {
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    }}>
                        <Link rel="preload" as="fetch" to="/Ayurvedic-Detoxification">
                            <Card image={images.gallery11} title="Ayurvedic Detoxification Retreat ( शोधनान्ति )"/>
                        </Link>
                    </Grid> */}
                </Grid.Container>
            </div>
        </>
    );
}