import React from "react";
import images from "../../constants/images";
import Director from "./Director";

const activities = [
	"Burma Bridge",
	"Bamboo Bridge",
	"Log Walk",
	"Balancing beam",
	"Parallel rope",
	"Zipline",
	"Trekking",
	"Rock climbing",
	"Rappelling",
	"Badminton",
	"Volleyball",
	"Mountaineering",
];

const Campspage = () => {
	return (
		<section className="text-gray-600 body-font">
			<div className="container  py-24 mx-auto flex flex-wrap">
				<div className="flex flex-wrap md:-m-2 -m-1">
					<div className="flex flex-wrap w-1/2">
						<div className="md:p-2 p-1 w-1/2 hover:scale-95 transition-all">
							<img
								alt="gallery"
								className="w-full object-cover h-full object-center block"
								src={images.camp1}
							/>
						</div>
						<div className="md:p-2 p-1 w-1/2 hover:scale-95 transition-all">
							<img
								alt="gallery"
								className="w-full object-cover h-full object-center block"
								src={images.camp2}
							/>
						</div>
						<div className="md:p-2 p-1 w-full hover:scale-95 transition-all">
							<img
								alt="gallery"
								className="w-full h-full object-cover object-center block"
								src={images.camp3}
							/>
						</div>
					</div>
					<div className="flex flex-wrap w-1/2 ">
						<div className="md:p-2 p-1 w-full hover:scale-95 transition-all">
							<img
								alt="gallery"
								className="w-full h-full object-cover object-center block"
								src={images.camp4}
							/>
						</div>
						<div className="md:p-2 p-1 w-1/2 hover:scale-95 transition-all">
							<img
								alt="gallery"
								className="w-full object-cover h-full object-center block"
								src={images.camp6}
							/>
						</div>
						<div className="md:p-2 p-1 w-1/2 hover:scale-95 transition-all">
							<img
								alt="gallery"
								className="w-full object-cover h-full object-center block"
								src={images.camp8}
							/>
						</div>
					</div>
				</div>
			</div>
			{/* text-description */}
			<div className="text-gray-600 body-font">
				<div className="container px-5 pb-24 mx-auto">
					<div className="flex flex-col text-center w-full mb-10">
						<h1 className="headtext__cormorant mb-4">
							Our Camps
						</h1>
						<p className="lg:w-2/3 mx-auto leading-relaxed text-base mb-4  text-justify sm:text-center">
							High Adventure Camp Manali Nestled amidst the
							picturesque apple orchards of Naggar, High Adventure
							Camp Manali is a sanctuary for adventurers seeking
							an escape into the heart of nature. Surrounded by
							the majestic Himalayas, our camp offers an
							unparalleled opportunity to immerse yourself in the
							beauty and tranquility of the mountains.
						</p>
						<p className="lg:w-2/3 mx-auto leading-relaxed text-base mb-4 text-justify sm:text-center">
							{" "}
							At High Adventure Camp Manali, we believe in the
							transformative power of outdoor experiences. Whether
							you’re an adrenaline junkie looking for thrilling
							activities or a nature lover seeking serenity, our
							camp has something for everyone. From trekking and
							mountaineering to camping and river rafting, we
							offer a wide range of adventurous activities that
							cater to all skill levels. Our cozy and comfortable
							accommodations provide the perfect refuge after a
							day of exploration, ensuring that you can relax and
							recharge for the next adventure. And with our
							delicious and hearty meals prepared with locally
							sourced ingredients, you’ll be energized to tackle
							whatever challenges the mountains throw your way.
						</p>
						<p className="lg:w-2/3 mx-auto leading-relaxed text-base text-justify sm:text-center">
							It is more than just a place to stay - it’s a
							community of like-minded individuals united by a
							passion for adventure and a love for the great
							outdoors. Whether you’re traveling solo, with
							friends, or as a family, you’ll find camaraderie and
							friendship around every corner. So come join us at
							High Adventure Camp Manali and embark on an
							unforgettable journey amidst the apple orchards of
							Naggar. Let the mountains inspire you, challenge
							you, and ultimately transform you! Adventure awaits!
						</p>
					</div>
					{/* Our Directors */}
					<h1 className="headtext__cormorant text-center">
						Our Director
					</h1>
					<Director />
					{/* Adventure section */}
					<h1 className="headtext__cormorant text-center ">
						Camp Activities
					</h1>
					<div className=" mt-10">
						<ul className="flex flex-wrap w-[80%] mx-auto gap-[10%] justify-between ">
							{activities.map((activity) => (
								<div className="flex gap-2 w-48 mb-4 ">
									<img
										src={images.checkList}
										alt="camp1"
										className="w-6 h-6"
									/>
									<li className="text-lg">{activity}</li>
								</div>
							))}
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Campspage;
