import "./App.css";
import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./components/Rcard/RCard.css";
import Header from "./cointainer/Header/Header";
import Package from "./cointainer/Packages/Packages";
import Room from "./cointainer/Rooms/Room";
import Services from "./cointainer/Services/Services";
import images from "./constants/images";
import data from "./constants/data";
import { Loading } from "@nextui-org/react";
import Navbar from "./cointainer/Navbar/Navbar";
import Camps from "./cointainer/camps/Camps";
import Campspage from "./cointainer/camps/Campspage";
const LazyFooter = React.lazy(() => import("./cointainer/Footer/Footer"));
const LazyGallery = React.lazy(() => import("./cointainer/Gallery/Gallery"));
const LazyNewGallery = React.lazy(() =>
  import("./cointainer/Gallery/NewGallery")
);
// const LazyBenefits = React.lazy(() => import("./cointainer/Benefits/Benefits"));
const LazyItenary = React.lazy(() => import("./cointainer/Itenary/Itenary"));
const LazyIntro = React.lazy(() => import("./cointainer/PackageIntro/intro"));
const LazyInclusions = React.lazy(() =>
  import("./cointainer/Inclusions/Inclusions")
);
const LazyNavbar = React.lazy(() => import("./components/Navbar/Navbar"));
const AboutUs = React.lazy(() => import("./cointainer/AboutUs/AboutUs"));
const AboutInclusion = React.lazy(() =>
  import("./cointainer/AboutUs/AboutInclusion")
);
const AboutMe = React.lazy(() => import("./cointainer/AboutUs/AboutMe"));
const FixedNavbar = React.lazy(() => import("./cointainer/Navbar/Navbar"));
const GalleryHome = React.lazy(() =>
  import("./cointainer/Gallery/GalleryPage")
);
// const PackageNew = React.lazy(() => import("./cointainer/Packages/Packagenew"));
const CallNow = React.lazy(() => import("./cointainer/CallButton/CallButton"));
const FooterXtra = React.lazy(() => import("./cointainer/Footer/FooterXtra"));

function App() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <>
                  <Navbar />
                  <Header />
                  <CallNow />
                  <Package />
                  <Room />
                  <Camps />
                  <LazyGallery />
                  <Services />
                  <AboutUs />
                  <LazyFooter />
                </>
              </React.Suspense>
            }
          />

          <Route
            path="/about-us"
            element={
              <React.Suspense fallback={<Loading />}>
                <>
                  <FixedNavbar />
                  <AboutInclusion />
                  <LazyFooter />
                  <CallNow />
                </>
              </React.Suspense>
            }
          />

          <Route
            path="/yeh-jawaani-hai-deewani"
            element={
              <React.Suspense fallback={<Loading />}>
                <>
                  <LazyNavbar
                    title="Yeh Jawaani Hai Deewani"
                    price={data.wayPrice}
                  />
                  <LazyNewGallery
                    featured={images.way1}
                    images={data.wayGallery}
                  />
                  <LazyIntro
                    title="Yeh Jawaani Hai Deewani"
                    description="If you’re tired of the busy city life there’s a perfect place for you to rejuvenate yourself. We will take you to Manali via much preferred overnight journey to a place where the climate is pleasant throughout summers. With a peaceful atmosphere, waking up to the chirping of the birds in the morning it the best way to detox and heal yourself mentally and physically. Manali has never failed to satisfy adventure enthusiasts looking for a captivating experience in the region. The day treks are ideal for people who want to experience trekking without having to go to places far away. A glimpse of the town can be covered in just three nights and four days because we have the perfect package for you right here!"
                  />
                  <LazyItenary itenary={data.waytohimalayas} />
                  <LazyInclusions
                    inclusion={data.wayInclusions}
                    exclusion={data.wayExclusions}
                  />
                  <LazyFooter />
                </>
              </React.Suspense>
            }
          />

          <Route
            path="/jab-we-met-manali"
            element={
              <React.Suspense fallback={<Loading />}>
                <>
                  <LazyNavbar
                    title="Jab We Met in Manali"
                    price={data.jwmPrice}
                  />
                  <LazyNewGallery
                    featured={images.jabwemet1}
                    images={data.jwmGallery}
                  />
                  <LazyIntro
                    title="Jab We Met in Manali"
                    description="If there is a perfect destination for romantic vacations in Himachal Pradesh, it has to be Manali without any doubt. Bestowed with the natural beauty of amazing mountains, rivers, waterfalls, glaciers and lakes, it is also complemented with the beautiful man-made cafes and restaurants with cosy interiors. The beauty of Manali is captivating. Experience the romantic riverside lunch by the banks of the river Beas, little coffee dates in the cosy cafes, skiing in the snow-filled valleys, the prepossessing sunset view from the top of the mountains after an adventurous trek and a lot more. Take away the beautifully handcrafted shawls and caps for your loved ones from this beautiful town. Set off for a honeymoon tour with HighAdventureTours and cherish the beautiful few days of your new life for a lifetime."
                  />
                  <LazyItenary itenary={data.jabwemet} />
                  <LazyInclusions
                    inclusion={data.jwmInclusions}
                    exclusion={data.jwmExclusions}
                  />
                  <LazyFooter />
                </>
              </React.Suspense>
            }
          />

          <Route
            path="/zindagi-na-milegi-dobara"
            element={
              <React.Suspense fallback={<Loading />}>
                <>
                  <LazyNavbar
                    title="Zindagi Na Milegi Dobara"
                    price={data.znmdPrice}
                  />
                  <LazyNewGallery
                    featured={images.znmd}
                    images={data.znmdGallery}
                  />
                  <LazyIntro
                    title="Zindagi Na Milegi Dobara"
                    description="Embark on a thrilling 4-night, 5-day adventure tour in Manali, a picturesque hill station nestled in the Himalayas. This tour is designed for adventure enthusiasts and nature lovers, offering a blend of adrenaline-pumping activities and serene natural beauty. From soaring high in the sky while paragliding to navigating the rapids of the Beas River, this tour promises an unforgettable experience."
                  />
                  <LazyItenary itenary={data.zindaginamilegidobara} />
                  <LazyInclusions
                    inclusion={data.znmdInclusions}
                    exclusion={data.znmdExclusions}
                  />
                  <LazyFooter />
                </>
              </React.Suspense>
            }
          />

          <Route
            path="/gallery"
            element={
                <React.Suspense fallback={<Loading />}>
                  <>
                    <FixedNavbar />
                    {[
                      images.home,
                      images.fullImage,
                      images.hotelimg,
                      images.food,
                      images.resortImg,
                    ].map((image, index) => (
                      <GalleryHome image = {image} index = {index}/>
                    ))}
                    <LazyFooter />
                    <CallNow />
                  </>
                </React.Suspense>
            }
          />

          <Route
            path="/campspage"
            element={
              <React.Suspense fallback={<Loading />}>
                <>
                  <FixedNavbar />
                  <Campspage />
                  <LazyFooter />
                  <CallNow />
                </>
              </React.Suspense>
            }
          />

          <Route
            path="/creatorurlsecret"
            element={
              <React.Suspense fallback={<Loading />}>
                <>
                  <FixedNavbar />
                  <AboutMe />

                  <GalleryHome image={images.meditationone} />
                  <GalleryHome image={images.meditationtwo} />

                  <FooterXtra />
                </>
              </React.Suspense>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
